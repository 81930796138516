import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';
import { LoginServices } from './LoginServices';
import * as OM from '@/Model';
import { StorageServices } from './StorageServices';
import { AppUserClient } from './Services';
import { getViewWithServices } from '@/utility';

class _CommonServices {

    showSpinner(){
        store.state.showSpinner++;
    };
    hideSpinner(){
        store.state.showSpinner--;
    };

    unauthorizedCb = () => {};
    
    private axios: AxiosInstance;
    defaultGet<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        url = replaceUrl(url, params);

        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.get<T>(url, req)
            .then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        console.log()
        return prom;
    }
    defaultPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch( err => {
                reject(err);
            })
            .finally( () => {
                if(_showSpinner)
                    this.hideSpinner();
            });
        });
        return prom;
    }

    uploadFileToUrl<T = any>(url: string, file: File, params: { [key: string]: any }, 
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        if (params) {
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        data.append('file', file);
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            return this.axios.post<T>(url, data, config).then(x => {
                this.hideSpinner();
                resolve(x.data);
            })
            .catch( err => {
                this.hideSpinner();
                reject(err);
            });
        });
        return prom;
    }

    setAuthToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.localStorage.setItem('authtoken', token);
    }
    destroyToken() {
        this.axios.defaults.headers.common['Authorization'] = "";
        window.localStorage.removeItem('authtoken');
    }

    constructor() {
        this.axios = Axios;
        let token = window.localStorage.getItem('authtoken');
        this.axios.interceptors.response.use(function (response: any) {
            return response;
        }, function (error) {
            const originalConfig = error.config;

            if (error.response.status == 401) {
                LoginServices.logout();
            }
            else if (error.response.status === 499 && !originalConfig._retry) {
                originalConfig._retry = true; //evita i loop
                if(this.refreshPromise){
                    return new Promise((resolve, reject) => {
                        this.refreshPromise
                        .then(x => {
                            originalConfig.headers.Authorization = "Bearer " + x.token;
                            resolve(this.axios(originalConfig));
                            return x;
                        }).catch( err => {
                            reject(err);
                            return err;
                        })
                    })
                } else {

                    var loggedUser = store.state.loggedUser;
                    var tokens: OM.AuthResponse = {
                        refreshToken: loggedUser.refreshToken,
                        token: loggedUser.token,
                        refreshTokenExpiryTime: ""
                    };

                    return new Promise((resolve, reject) => {
                        this.refreshPromise = AppUserClient.refreshToken(tokens)
                        .then(refreshTokenResponse => {
                            var token = refreshTokenResponse.token;
                            
                            StorageServices.setNewTokensByAuthResponse(refreshTokenResponse);
                            
                            originalConfig.headers.Authorization = "Bearer " + token;
                            
                            resolve(this.axios(originalConfig));
                            this.refreshPromise = null;
                            return refreshTokenResponse;
                        }).catch( err => {
                            this.refreshPromise = null;
                            LoginServices.logout();
                            
                            var data = {
                                Message: getViewWithServices().$localizationService.getLocalizedValue("app_LaTuaSessioneEScaduta", "La tua sessione è scaduta! <br />  Effettua di nuovo l'accesso."),
                            };
                            return Promise.reject(data);
                        })
                    })
                }
            }
            else if (error.response.status === 499 && originalConfig._retry) {
                LoginServices.logout();
                var data = {
                    Message: getViewWithServices().$localizationService.getLocalizedValue("app_LaTuaSessioneEScaduta", "La tua sessione è scaduta! <br />  Effettua di nuovo l'accesso."),
                };
                return Promise.reject(data);
            }
            else {
                if(error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
        if (token){
            this.setAuthToken(token);
        }
    }
}

function replaceUrl(url: string, params?: any): string {

    if(!params || url.indexOf("{") == -1)
        return url;

    var beginIndex = url.indexOf("{");
    var endIndex = url.indexOf("}");

    var property = url.substring(beginIndex + 1, endIndex);
    var value = params[property];

    console.log(value)

    url = url.substring(0, beginIndex) + value + url.substr(endIndex + 1);
    delete params[property];

    return replaceUrl(url, params);
}

export let CommonServices = new _CommonServices();